<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <slot></slot>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  //   IonBackButton,
  IonPage,
  IonTitle,
  IonToolbar,
  //   IonIcon,
} from "@ionic/vue";
// import { menuOutline } from "ionicons/icons";

export default {
  name: "BaseLayout",
  props: {
    title: String,
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    // IonBackButton,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonIcon,
  },
};
</script>