<template>
  <base-layout title="Finulator">
    <!-- Tap the button in the toolbar to open the menu. -->
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-card>
            <img alt="1" src="../assets/budget.png" />
            <ion-card-header>
              <ion-card-subtitle>Card Subtitle</ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col>
          <ion-card>
            <img alt="2" src="../assets/budget.png" />
            <ion-card-header>
              <ion-card-subtitle>Card Subtitle</ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col>
          <ion-card>
            <img alt="3" src="../assets/budget.png" />
            <ion-card-header>
              <ion-card-subtitle>Card Subtitle</ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col>
          <ion-card>
            <img alt="4" src="../assets/budget.png" />
            <ion-card-header>
              <ion-card-subtitle>Card Subtitle</ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col>
          <ion-card>
            <img alt="5" src="../assets/budget.png" />
            <ion-card-header>
              <ion-card-subtitle>Card Subtitle</ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col>
          <ion-card>
            <img alt="6" src="../assets/budget.png" />
            <ion-card-header>
              <ion-card-subtitle>Card Subtitle</ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>
  
<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  //   IonThumbnail,
  IonCard,
  IonCardHeader,
//   IonCardTitle,
IonCardSubtitle
} from "@ionic/vue";

import BaseLayout from "../views/BaseLayout.vue";

export default {
  name: "HomePage",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    // IonThumbnail,
    IonCard,
    IonCardHeader,
    // IonCardTitle,
    IonCardSubtitle,
    BaseLayout,
  },
};
</script>

<style>
    ion-card {
        padding: 5px;
    }
</style>